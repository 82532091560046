
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { filterRole } from '@/utils/common';
import { ElMessageBox } from 'element-plus';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const logOut = () => {
      ElMessageBox.confirm('确定要退出登录么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          sessionStorage.clear();
          Cookies.remove('qqconnect_access_token');
          router.push('Login');
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      filterRole,
      userInfo: computed(() => store.getters.userInfo),
      logOut,
    };
  },
});
