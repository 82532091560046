<template>
  <div class="header">
    <el-dropdown trigger="click">
      <div class="user-info">
        <img :src="userInfo.icon" alt="" width="32" height="32" />
        <div class="name-role">
          <p class="name">{{ userInfo.nickName }}</p>
          <p class="role">{{ filterRole(userInfo.roleId)  }}</p>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item icon="el-icon-switch-button" @click="logOut">退出登录</el-dropdown-item>
         </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script lang='ts'>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { filterRole } from '@/utils/common';
import { ElMessageBox } from 'element-plus';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const logOut = () => {
      ElMessageBox.confirm('确定要退出登录么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          sessionStorage.clear();
          Cookies.remove('qqconnect_access_token');
          router.push('Login');
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      filterRole,
      userInfo: computed(() => store.getters.userInfo),
      logOut,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 56px;
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(52, 57, 82, 0.12);
}
.user-info {
  display: flex;
  align-items: center;
  padding-right: 44px;
  cursor: pointer;
  img {
    border-radius: 50%;
  }
  .name-role {
    margin-left: 10px;
  }
  .name {
    color: #262626;
    font-weight: 400;
    font-size: 14px;
    line-height:1;
    margin-top: 14px;
  }
  .role {
    color: rgba($color: #000, $alpha: 0.15);
    font-size: 12px;
    line-height:1;
    margin-top: 6px;
  }
}
</style>
