<template>
  <div class="sidebar">
    <div class="title">
      <img :src="logoPng" alt="">
    </div>
    <el-menu
      class="sidebar-el-menu"
      :default-active="defaultPath"
      :collapse="collapse"
      background-color="#343952"
      text-color="#FFFFFF"
      active-text-color="#4D74FF"
      unique-opened
      router
    >
      <div v-show="showSider">
        <template v-for="item in sibarList">
          <template v-if="item.children">
            <el-submenu :index="item.path" :key="item.path">
              <template #title>
                <i :class="item.meta.icon"></i>
                <span>{{ item.meta.cnName }}</span>
              </template>
              <el-menu-item v-for="subItem in item.children" :index="subItem.path" :key="subItem.path">
                {{ subItem.meta.cnName }}
              </el-menu-item>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :index="item.path" :key="item.path">
              <i :class="item.meta.icon"></i>
              {{ item.meta.cnName }}
            </el-menu-item>
          </template>
        </template>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import logoPng from '../../assets/img/logo.png';
import {  homeChildrenRoutes } from '@/router/index';
import { filterRouter } from '@/permission';
export default defineComponent({
  props: {
    showSider: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    console.log(store, 'store');
    const { userInfo } = store.getters;
    const items = ref(filterRouter(userInfo.roleId, homeChildrenRoutes));
    const collapse = ref(false);
    const router = useRoute();
    const sibarList = computed(() => items.value.filter(e => e.component));
    const defaultPath = computed(() => router.path);
    return { sibarList, collapse, defaultPath, logoPng };
  },
});
</script>

<style scoped lang="scss">
.title {
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  background-color: #343952;
}
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 190px;
  border-right: 0;
}
.sidebar > ul {
  height: calc(100% - 56px);
  overflow: hidden;
}

.el-menu-item i {
  color: #fff;
}
.el-menu-item.is-active i{
  color:#4D74FF;
}
</style>
