

/**
 *
 * 根据角色ID装换成角色名字
*/


const filterRole = (roleId: number): string => {
  switch (roleId) {
    case 1:
      return '管理员';
    case 2:
      return '厂商';
    case 3:
      return '版主';
    case 4:
      return '写手';
    default:
      return '无效角色(请联系管理员)';
  }
};


/**
 *
 * 根据图片地址获取图片的宽高
 *
 */


interface IgetImageSize {
  width: number;
  height: number;
}

function getImageSize(imgUrl: string): Promise<IgetImageSize> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = (e) => {
      reject(new Error(`getImageSize fail, img onerror:' + ${e}`));
    };
    img.src = imgUrl;
  });
}

export {
  filterRole,
  getImageSize,
};


